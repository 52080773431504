import { Component, OnInit } from '@angular/core';

import { Platform, Events, NavController } from '@ionic/angular';
import { ApiService } from './services/api.service';
import { User } from './models/User';
import { UtilitiesService } from './services/utilities.service';
import { environment } from "../environments/environment";

var cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  public user: User;
  public token: string;
  public whatsapp: boolean = false;

  public pagesAuth = [
    {
      title: 'Categorías',
      url: '/categorias',
      icon: 'list'
    },
  
    
    
  ];

  public pages = [
    {
      title: 'Acceder',
      url: '/login',
      icon: 'person'
    },
    
  
    
  ];


  constructor(private platform: Platform,

    private apiService: ApiService,
    private utilities: UtilitiesService,
    private events: Events,
    private navCtrl: NavController) {
  }

  /**
   * Nos suscribimos a los cambios dle perfil
   */
  public ngOnInit(): void {
    this.loginImplicito();

    this.events.subscribe('user:login', () => {
      this.token = "token";
    });
    this.events.subscribe('activar-whatsapp', (activo: boolean) => {
      this.whatsapp = activo;
    });


    this.apiService.userChanges.subscribe((user: User) => {
      this.user = user;
    });
  }


  /**
   * Configuración de las notificación push
   */


  /**
   * Login implícito
   */
  public async loginImplicito() {
    this.token = await this.apiService.getTokenStorage();
    if (this.token) {
      await this.apiService.setTokenToHeaders(this.token);
      this.navCtrl.navigateRoot('/categorias');
    } else {
      this.navCtrl.navigateRoot('/login');
    }
  }

  /**
   * Método para cerrar sesión
   */
  public async logout() {
    this.apiService.clearStorage();
    this.apiService.setTokenToHeaders(null);
    this.token = null;
    this.navCtrl.navigateRoot('/login');
  }

 

  /**
   * Preparamos stripe con su configuración
   */


}
