import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { User } from '../models/User';

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilitiesService } from './utilities.service';
import { Storage } from '@ionic/storage';
import { Categoria } from '../models/Categoria';
import { Producto } from '../models/Producto';
import { SubCategoria } from '../models/SubCategoria';




@Injectable({
  providedIn: 'root'
})

export class ApiService {

  public userChanges = new Subject<User>();
  public httpOptions: any;
  public categorias: Categoria[];

  constructor(public http: HttpClient,
    private utilities: UtilitiesService,
    private storage: Storage) { }

  /**
   * Método para iniciar sesión
   * @param email 
   * @param password 
   */
  public login(user: User) {
    return this.http.post<User>(environment.apiUrl + 'login', user);
  }
  public access(user: User) {
    return this.http.post<User>(environment.apiUrl + 'access', user);
  }
  /**
   * Método para el registro básico
   * @param user 
   */
  public register(user: User) {
    return this.http.post(environment.apiUrl + 'signupglass', user);
  }

  /**
   * Método para recuperar contraseña
   * @param email 
   */
  public forgotPassword(email: string) {
    return this.http.post(environment.apiUrl + 'forgot-password', email);
  }

  /**
   * Método para añadir el bearer token a las cabeceras 
   */
  public setTokenToHeaders(token: string): void {

    //Asignar token a las siguientes peticiones
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      })
    };
  }

  /**
   * Guardamos el token de sesion en el storage
   */
  public setTokenStorage(token: string): void {
    //Guardamos el token en el storage
    this.storage.set('api_token', token);
  }

  /**
   * Devolvemos el token del storage 
   */
  public getTokenStorage() {
    return this.storage.get('api_token');
  }

  /**
   * Borramos todos los datos del storage
   */
  public clearStorage() {
    this.storage.clear();
    this.storage.set('api_token', null);
  }


  /**
   * Método para obtener los datos del usuario
   */
  public getUser(): any {
    return this.http.get<User>(environment.apiUrl + 'user', this.httpOptions);
  }

  /**
   * Método para actualizar los datos del usuario
   * @param user 
   */
  public updateUser(user: User): any {
    this.userChanges.next(user);
    return this.http.post<User>(environment.apiUrl + 'update-user', user, this.httpOptions);
  }

  /**
   * Método para obtener las traducciones
   */
  public getTranslations() {
    return this.http.get(environment.apiUrl + 'traducciones', this.httpOptions);
  }

  /**
    * Guardar el token del dispositivo en el servidor
    * @param tokenRegistro 
    */
  public guardarTokenDeRegistro(tokenRegistro) {
    return this.http.post(environment.apiUrl + 'guardar-token', { registerToken: tokenRegistro, platform: this.utilities.getPlatform() }, this.httpOptions);
  }

  /**
* Método para procesar el pago
*/
  public procesarPago(params: { precio: number, stripeToken?: any }): any {

    return this.http.post(environment.apiUrl + 'pago', params, this.httpOptions);
  }

  // ====================== Métodos añadidos ==========================

  /**
   * Obtenemos todas las categorias
   */
  public getApiCategorias(): any {
    return this.http.get<Categoria[]>(environment.apiUrl + 'categoriascomp', this.httpOptions);
  }

  /**
   * Obtenemos todas las categorias
   */
  public getCategorias(): Categoria[] {
    if (!this.categorias)
      this.getApiCategorias().subscribe((ca: Categoria[]) => {
        this.categorias = ca;
      });
    return this.categorias;
  }
  /**
   * Obtenemos los productos según categoría
   * @param id_categoria 
   */
  public getProductosCategoria(id_categoria: number): any {
    return this.http.get<Producto[]>(environment.apiUrl + 'productos-categoriacomp/' + id_categoria, this.httpOptions);
  }
  public getAllProductos(): any {
    return this.http.get<Producto[]>(environment.apiUrl + 'get-productoscomp', this.httpOptions);
  }
  /**
   * Obtenemos los productos según categoría
   * @param id_categoria 
   */
  public getProductosSubCategoria(id_sub_categoria: number): any {
    return this.http.get<Producto[]>(environment.apiUrl + 'productos-subcategoriacomp/' + id_sub_categoria, this.httpOptions);
  }

  /**
   * Obtenemos las subcategorías según categoría
   * @param id_categoria 
   */
  public getSubCategorias(id_categoria: number): any {
    return this.http.get<SubCategoria[]>(environment.apiUrl + 'sub-categoriascomp/' + id_categoria, this.httpOptions);
  }

  /**
   * Loguearse con facebook
   * @param user 
   */
  public loginFacebook(user: User): any {
    return this.http.post<User>(environment.apiUrl + 'login-facebook', user, this.httpOptions);
  }

  /**
   * Loguearse con google
   * @param user 
   */
  public loginGoogle(user: User): any {
    return this.http.post<User>(environment.apiUrl + 'login-google', user, this.httpOptions);
  }

  public getProducto(id_producto: number): any {
    return this.http.get<Producto>(environment.apiUrl + 'productocomp/' + id_producto, this.httpOptions);
  }
  public getNombreProductos(): any {
    return this.http.get(environment.apiUrl + 'nombre-productoscomp', this.httpOptions);
  }
  
  public getNameProductos(producto_id): any {
    return this.http.get(environment.apiUrl + 'name-productoscomp/' + producto_id, this.httpOptions);
  }

  public getNombreProductosCategoria(id: number): any {
    return this.http.get(environment.apiUrl + 'nombre-productos-categoriacomp/' + id, this.httpOptions);
  }
  public getCategoriasProducto(product_id: number): any {
    return this.http.get(environment.apiUrl + 'categorias-productocomp/' + product_id, this.httpOptions);
  }

}
