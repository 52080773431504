import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Producto } from 'src/app/models/Producto';
import { ApiService } from 'src/app/services/api.service';
import {SubCategoriasPage} from '../sub-categorias/sub-categorias.page'


@Component({
  selector: 'app-filter',
  templateUrl: './filter.page.html',
  styleUrls: ['./filter.page.scss'],
})
export class FilterPage implements OnInit {

  public producto: Producto = {
    altura: null,
    anchura: null,
    filo_inferior: null,
    posicion_camara: null,
  };
  constructor(private modalCtrl: ModalController,){}

  public ngOnInit() {
  
  }

public buscar(){
  this.modalCtrl.dismiss(this.producto)
  
}
public cancel(){
  this.modalCtrl.dismiss('cancel')
}

}
