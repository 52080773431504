import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'logout', redirectTo: 'login', pathMatch: 'full' },

  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) },
  //Categorias
  { path: 'categorias', loadChildren: () => import('./pages/categorias/categorias.module').then(m => m.CategoriasPageModule) },
  { path: 'categorias/detalle-producto/:id_producto', loadChildren: () => import('./pages/detalleproducto/detalleproducto.module').then(m => m.DetalleproductoPageModule) },
  
  { path: 'categorias/sub-categorias/:id_categoria', loadChildren: () => import('./pages/sub-categorias/sub-categorias.module').then(m => m.SubCategoriasPageModule) },
  { path: 'categorias/sub-categorias/:id_categoria/detalle-producto/:id_producto', loadChildren: () => import('./pages/detalleproducto/detalleproducto.module').then(m => m.DetalleproductoPageModule) },
  { path: 'categorias/sub-categorias/:id_categoria/litproductos/:id_categoria', loadChildren: () => import('./pages/litproductos/litproductos.module').then(m => m.LitproductosPageModule) }, { path: 'litproductos', loadChildren: () => import('./pages/litproductos/litproductos.module').then(m => m.LitproductosPageModule) },
  { path: 'categorias/litproductos/:id_categoria', loadChildren: () => import('./pages/litproductos/litproductos.module').then(m => m.LitproductosPageModule) }, { path: 'litproductos', loadChildren: () => import('./pages/litproductos/litproductos.module').then(m => m.LitproductosPageModule) },
  { path: 'categorias/sub-categorias/:id_categoria/litproductos/:id_categoria/detalleproducto/:id_producto', loadChildren: () => import('./pages/detalleproducto/detalleproducto.module').then(m => m.DetalleproductoPageModule) },
  { path: 'categorias/litproductos/:id_categoria/detalleproducto/:id_producto', loadChildren: () => import('./pages/detalleproducto/detalleproducto.module').then(m => m.DetalleproductoPageModule) },

  { path: 'detalle-producto', loadChildren: () => import('./pages/detalleproducto/detalleproducto.module').then(m => m.DetalleproductoPageModule) },

  










];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
